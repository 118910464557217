// * Authentication
// *******************************************************************************

@use '../_bootstrap-extended/include' as light;
@use '../_bootstrap-extended/include-dark' as dark;
@import '../_custom-variables/pages';

$authentication-1-inner-max-width: 450px !default;

.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;

  .authentication-inner {
    width: 100%;
  }

  &.authentication-basic {
    align-items: center;
    justify-content: center;
  }
  .auth-cover-illustration {
    z-index: 1;
    max-inline-size: 38rem;
  }
  .authentication-image {
    z-index: -1;
    inline-size: 100%;
    inset-block-end: 7%;
    position: absolute;
    inset-inline-start: 0;
  }

  &.authentication-cover {
    align-items: flex-start;
    .authentication-inner {
      min-height: 100vh;
      position: relative;
    }
    .authentication-image {
      inline-size: 70%;
      @include app-ltr() {
        inset-inline-start: unset;
      }
    }
  }

  &.authentication-basic .authentication-inner {
    max-width: $authentication-1-inner-max-width;
  }

  // For two-steps auth
  .auth-input-wrapper .auth-input {
    max-width: 50px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: light.$large-font-size;
  }
}

// Two-steps auth responsive style
@include light.media-breakpoint-down(sm) {
  .authentication-wrapper {
    .auth-input-wrapper .auth-input {
      font-size: light.$h5-font-size;
    }
  }
}

// Responsive style for cover mask
@include light.media-breakpoint-down(xl) {
  .authentication-cover {
    .authentication-image {
      inset-block-end: 9%;
      @include app-rtl() {
        inline-size: 65%;
      }
    }
  }
}

// Two Steps Verification
// ? Used for validation specific style as we have validated hidden field
#twoStepsForm {
  .fv-plugins-bootstrap5-row-invalid .form-control {
    border-color: light.$form-feedback-invalid-color;
  }
}

// Light Layout
@if $enable-light-style {
  .light-style {
    .authentication-wrapper .authentication-bg {
      background-color: light.$white;
    }
  }
}

// Dark Layout
@if $enable-dark-style {
  .dark-style {
    .authentication-wrapper .authentication-bg {
      background-color: dark.$card-bg;
    }
  }
}
